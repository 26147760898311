<template>
  <section>
    <ag-grid-table
      ref="grid-historico-lineas-productivas"
      :configUrl="historicoLineasProductivasConfig"
      :dataUrl="historicoLineasProductivasData"
      :editUrl="historicoLineasProductivasEdit"
      actionsCellRenderer="customActionsCellRender3"
      :actions="actions"
      :helper="true"
    >
    </ag-grid-table>

    <b-modal
      :ref="'detalle-blending-modal'"
      ok-only
      ok-title="Accept"
      centered
      size="xl"
      :title=" 'Detalle Línea: ' "
     
    >
      <b-card no-body>
        <div class="card-header">
          <!-- Title & SubTitle -->
          <div>
            <b-card-title></b-card-title>
            <b-card-sub-title></b-card-sub-title>
          </div>

        </div>

        <!-- Card Body  -->
        <b-card-body style="width: 100%">
          <b-list-group v-if="verDetallesData">
            <b-list-group-item v-for="(value, key) in verDetallesData.data['meta_data']" :key="key">
              <div v-if="key != 'formatos' && key != 'available_on_holidays' && key != 'available_on_weekends'">
                <b>{{ key.replace(/_+/g, ' ') }}:</b> {{ value }}
              </div>
              <div v-if="key == 'formatos'">
                <div v-for="(data, key) in value" :key="key">
                  <p><b>{{ key + 1 }}. </b> <b>Formato:</b> {{ data.formato }}, <b>Velocidad:</b> {{ data.capacidad_velocidad }}, <b>Unidad:</b> {{ data.unidad }}</p>
                </div>
              </div>
              <div v-if="key == 'available_on_holidays'">
                <b>{{ key.replace(/_+/g, ' ') }}:</b> {{ value ? 'Si' : 'No'}}
              </div>
              <div v-if="key == 'available_on_weekends'">
                <b>{{ key.replace(/_+/g, ' ') }}:</b> {{ value ? 'Si' : 'No'}}
              </div>
            </b-list-group-item>
                 
          </b-list-group>


        </b-card-body>
      </b-card>
    </b-modal>


  </section>
</template>

<script>

import { VueAutosuggest } from 'vue-autosuggest'

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";

// import HistoricoLineasActions from './HistoricoLineasActions.vue';

import Vue from 'vue'

import
{
  BRow,
  BCol,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BCard,
  BCardBody,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormDatepicker,
  BCardTitle,
  BCardSubTitle,
  VBTooltip,
  VBPopover
} from "bootstrap-vue";
import vSelect from 'vue-select';
import axios from "@axios";


import useApiServices from '@/services/useApiServices.js';
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'


export default {

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  props: {
  },

  data()
  {




    return {

      actions: [
        { name: "Detalles", icon: "AlignJustifyIcon", action: this.verDetalles },
        { name: "Eliminar", icon: "Trash2Icon", action: this.delete },
        //{ name: "Descargar", icon: "DownloadIcon", action: (params) => { console.log("Descargar", params); } },
      ],

      historicoLineasProductivasConfig: useApiServices.historicoLineasProductivasConfig,
      historicoLineasProductivasData: useApiServices.historicoLineasProductivasData,
      historicoLineasProductivasEdit: useApiServices.historicoLineasProductivasEdit,

      verDetallesData: null






    };
  },
  components: {
    BRow,
    BCol,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BCard,
    BCardBody,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormDatepicker,
    ToastificationContent,
    vSelect,
    BCardTitle,
    BCardSubTitle,
    VBPopover,
    VBTooltip,
    VueAutosuggest,
    BrunaForm,
    AgGridTable,

    // "historicoLineasActions": HistoricoLineasActions
  },

  created()
  {


  },

  mounted()
  {




  },


  methods: {


    verDetalles(params){

      console.log("verDetalles", params);

      this.verDetallesData = params

      this.$refs['detalle-blending-modal'].show()

    },

  


    delete(params)
    {
      console.log(params)
      let data = params.data


      this.$bvModal.msgBoxConfirm('Confirma si quieres eliminar la línea', {
        title: 'Confirmar',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Eliminar',
        cancelTitle: 'Cancelar',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value =>
        {

          if (value)
          {
            useApiServices.postLineasDelete(data)
              .then((response) =>
              {

                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Eliminación Exitosa`,
                    icon: "UploadIcon",
                    variant: "success",
                    html: `La línea se ha eliminado.`,
                  },
                });

                this.$refs['grid-historico-lineas-productivas'].refreshRows()


              })
              .catch((error) =>
              {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Ocurrió un Error`,
                    icon: "UploadIcon",
                    variant: "danger",
                    html: `Ha ocurrido un error</b>.`,
                  },
                });

              });


          }


        })
        .catch(err =>
        {
          // An error occurred
        })


    },


    refreshRows()
    {
      this.$refs['grid-historico-lineas-productivas'].refreshRows()
    }



  },
};
</script>

<style scoped>
.titulo {
  margin-top: 5%;
}
</style>

<style lang="scss">
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>