<template>
  <section>
    <BrunaForm
      ref="nuevaLineaRef"
      v-bind:fields="this.nueva_linea.fields"
      :card="this.nueva_linea.card"
      @change="nuevaLineaChange"
    ></BrunaForm>

    <BrunaForm
      v-for="(formato, key) in formatos"
      :key="key"
      ref="formatos"
      v-bind:fields="formato.fields"
      :card="formato.card"
      @change="nuevaLineaChange"
    ></BrunaForm>

    <b-card>
      <b-card-body>
        <b-button
          variant="primary"
          class="mt-4 float-right"
          @click="save"
          size="lg"
          >Guardar</b-button
        >
      </b-card-body>
    </b-card>
  </section>
</template>

<script>

import { VueAutosuggest } from 'vue-autosuggest'

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";


import
{
  BRow,
  BCol,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BCard,
  BCardBody,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormDatepicker,
  BCardTitle,
  BCardSubTitle,
  VBTooltip,
  VBPopover
} from "bootstrap-vue";
import vSelect from 'vue-select';
import axios from "@axios";


import useApiServices from '@/services/useApiServices.js';

export default {

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  props: {
    forecast: {
      type: Object,
      default: () => null
    }
  },

  data()
  {



    return {

      formatos: [
      ],


      nueva_linea: {
        card: {
          title: "Crear Nueva Línea",
          subtitle: "",
          tooltip: ""
        },
        fields: [


          {
            label: "Nombre Línea",
            type: "input",
            id: 'nombre_linea',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null,
            show: true
          },

          {
            label: "Ajustes Capacidad Velocidad (%)",
            type: "input",
            id: 'ajustes_capacidad_velocidad',
            rules: 'required|numeric2|between:0,100',
            tooltip: null,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: 100
          },

          {
            label: "Estado",
            type: "select",
            id: 'status',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: "Activada", text: "Activada" },
              { value: "Desactivada", text: "Desactivada" },

            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Tipo",
            type: "select",
            id: 'tipo',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: "Real", text: "Real" },
              { value: "Virtual", text: "Virtual" },

            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Planta",
            type: "select",
            id: 'planta',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: "Planta 1", text: "Planta 1" },
              { value: "Planta 2", text: "Planta 2" },
              { value: "Planta 3", text: "Planta 3" },

            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },


          {
            label: "Horas Funcionamiento Diario",
            type: "input",
            id: 'horas_funcionamiento_diario',
            rules: 'required|numeric2|between:0,24.00',
            tooltip: null,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },
          {
            label: "Horas de Mantención",
            type: "input",
            id: 'horas_mantencion',
            rules: 'required|numeric2',
            tooltip: null,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Días de Funcionamiento Semanal",
            type: "input",
            id: 'dia_de_funcionamiento_semana',
            rules: 'required|numeric2|between:1,7',
            tooltip: null,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },
          {
            label: "Número de Formatos",
            type: "select",
            id: 'numero_formatos',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: 1, text: "1" },
              { value: 2, text: "2" },
              { value: 3, text: "3" },

            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Disponible Fines de Semana",
            type: "checkbox",
            id: 'available_on_weekends',
            rules: 'required',
            format: {
              xs: 2,
              sm: 2,
              md: 2, 
              lg: 2,
              xl: 2
            },
            value: false,
          },

          {
            label: "Disponible Feriados",
            type: "checkbox",
            id: 'available_on_holidays',
            rules: 'required',
            format: {
              xs: 2,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2
            },
            value: false,
          },  
        ]
      },




    };
  },
  components: {
    BRow,
    BCol,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BCard,
    BCardBody,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormDatepicker,
    ToastificationContent,
    vSelect,
    BCardTitle,
    BCardSubTitle,
    VBPopover,
    VBTooltip,
    VueAutosuggest,
    BrunaForm,
  },

  created()
  {


  },

  mounted()
  {




  },


  methods: {



    formatData()
    {
      let nueva_linea = this.nueva_linea.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {})

      nueva_linea['formatos'] = []


      this.formatos.forEach((formato, index) =>
      {
        nueva_linea['formatos'][index] = formato.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {})
      })


      console.log(nueva_linea);

      return {
        nueva_linea: nueva_linea,
      }


    },


    createNuevaLinea()
    {



      useApiServices.postLineasCreate(this.formatData(), this)
        .then((response) =>
        {

          this.$emit('nueva-linea-agregada');

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Carga Exitosa`,
              icon: "UploadIcon",
              variant: "success",
              html: `La linea se ha creado exitosamente.`,
            },
          });


        })
        .catch((error) =>
        {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Ocurrio un Error`,
              icon: "UploadIcon",
              variant: "danger",
              html: `Ha ocurrido un error al guardar la nueva linea</b>.`,
            },
          });

        });





    },


    save()
    {



      let self = this;

      Promise.all([
        this.$refs.nuevaLineaRef.validate()
      ]).then(([nuevaLineaValid]) =>
      {

        if (nuevaLineaValid)
        {
          let validation_promises = self.$refs.formatos.map(formato => formato.validate());

          Promise.all(
            validation_promises
          ).then((valids) => { 

            let everyIsValid = valids.every((valid) => valid === true)

            if(everyIsValid)
            {
              self.createNuevaLinea()
            }

          })

        }


      })


    },



    findFieldByName(fields, name)
    {

      return fields.find(field => field.id == name)

    },





    nuevaLineaChange(field)
    {
      console.log(field)

      if (field.id == "numero_formatos")
      {
        let formatos = []

        for (let i = 0; i < field.value; i++)
        {

          formatos.push(

            {
              card: {
                title: "Nuevo Formato " + (i + 1),
                subtitle: "",
                tooltip: ""
              },
              fields: [


                {
                  label: "Formato",
                  type: "select",
                  id: 'formato',
                  rules: 'required',
                  options: [
                    { value: null, text: "Selecciona una opción" },
                    { value: "27x45", text: "27x45" },
                    { value: "36x36", text: "36x36" },
                    { value: "60x60", text: "60x60" },
                    { value: "30x60", text: "30x60" },
                    { value: "45x45", text: "45x45" },
                    { value: "20x61", text: "20x61" },
                    { value: "60x45", text: "60x45" },

                  ],
                  format: {
                    xs: 4,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    xl: 4
                  },
                  value: null
                },


                {
                  label: "Capacidad/Velocidad",
                  type: "input",
                  id: 'capacidad_velocidad',
                  rules: 'required|numeric2',
                  format: {
                    xs: 4,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    xl: 4
                  },
                  value: null,
                },


                {
                  label: "Unidad",
                  type: "select",
                  id: 'unidad',
                  rules: 'required',
                  options: [
                    { value: null, text: "Selecciona una opción" },
                    { value: "mt2/dia", text: "mt2/dia" },

                  ],
                  format: {
                    xs: 4,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    xl: 4
                  },
                  value: null
                },




              ]
            }



          )


        }

        this.formatos = formatos


      }


    },


  },
};
</script>

<style scoped>
.titulo {
  margin-top: 5%;
}
</style>

<style lang="scss">
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>